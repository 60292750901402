/*
 * @Author: wangqs 
 * @description 完善授权信息
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-01-26 15:32:26
 */
<template>
  <div class="page-box box">
    <div class="top-box">
      <div class="text-a">
        完善授权信息
      </div>
      <div class="text-b">
        完善信息，以便于您进行分期业务
      </div>
    </div>

    <!--  -->
    <div
      class="box"
      style="padding-top: 10px;"
    >
      <van-field
        v-model="orderInfo.companyName"
        required
        label="单位名称"
        placeholder="请输入单位名称"
      />
      <van-field
        v-model="orderInfo.workAddr"
        required
        label="单位地址"
        placeholder="请输入单位地址"
      />
      <van-field
        v-model="orderInfo.email"
        required
        label="邮箱"
        placeholder="请输入常用邮箱"
      />
      <van-field
        readonly
        required
        clickable
        name="area"
        :value="addrStr"
        label="家庭地址"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-popup
        v-model="showArea"
        position="bottom"
      >
        <van-area
          :value="orderInfo.areaNo || ''"
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="showArea = false"
        />
      </van-popup>

      <van-field
        v-model="orderInfo.detailAddr"
        required
        label="详细地址"
        placeholder="请输入详细地址"
      />

      <van-field
        readonly
        required
        clickable
        name="picker"
        :value="orderInfo.income"
        label="家庭收入"
        placeholder="点击选家庭收入"
        @click="showPickerA = true"
      />
      <van-popup
        v-model="showPickerA"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="incomeArr"
          @confirm="onConfirmA"
          @cancel="showPickerA = false"
        />
      </van-popup>

      <van-field
        readonly
        required
        clickable
        name="picker"
        :value="orderInfo.education"
        label="学历"
        placeholder="点击选学历"
        @click="showPickerB = true"
      />
      <van-popup
        v-model="showPickerB"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="educationArr"
          @confirm="onConfirmB"
          @cancel="showPickerB = false"
        />
      </van-popup>

      <van-field
        readonly
        required
        clickable
        name="picker"
        :value="jobStr"
        label="职务"
        placeholder="点击选职务"
        @click="showPickerC = true"
      />
      <van-popup
        v-model="showPickerC"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="pickerC"
          @confirm="onConfirmC"
          @cancel="showPickerC = false"
        />
      </van-popup>

      <van-field
        readonly
        required
        clickable
        name="picker"
        :value="marriedStr"
        label="婚姻状况"
        placeholder="点击选婚姻状况"
        @click="showPickerD = true"
      />
      <van-popup
        v-model="showPickerD"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="pickerD"
          @confirm="onConfirmD"
          @cancel="showPickerD = false"
        />
      </van-popup>

      <van-field
        v-model="orderInfo.contractName"
        required
        label="紧急联系人"
        placeholder="请输入紧急联系人姓名"
      />

      <!-- 输入手机号，调起手机号键盘 -->
      <van-field
        v-model="orderInfo.contractMobile"
        required
        type="tel"
        placeholder="输入紧急联系人电话"
        label="联系人电话"
      />
      <!-- 提交 -->
      <van-button
        class="submit-btn"
        color="#51B464"
        round
        type="info"
        @click="nextBtn"
      >
        下一步
      </van-button>
    </div>

    <!-- code 码 -->
    <van-overlay
      style="background: rgba(0,0,0,0.3)"
      :show="show"
      @click="show = false"
    >
      <div
        class="wrapper-box"
        @click.stop="show = false"
      >
        <div
          class="block-box"
          @click.stop
        >
          <div class="text-a">
            请填写手机短信验证码
          </div>
          <div class="text-b">
            已发送到15800191877
          </div>
          <van-field
            v-model="text"
            maxlength="6"
            class="text-c"
            label="验证码"
          />
          <van-button
            class="code-btn"
            color="#51B464"
            round
            type="info"
            @click="show = false"
          >
            提交
          </van-button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
export default {
  name: 'BasicInfo',
  components: {
  },
  data () {
    return {
      text: '',
      show: false,
      value: '',
      form: { addr: '' },
      showArea: false,
      areaList,
      incomeArr: [
        '5万以下',
        '5-30万',
        '30万以上'
      ],
      educationArr: ['小学', '初中', '高中', '大专', '本科', '硕士', '博士及以上'],
      pickerC: ['股东','实际控制人','高管','部门负责人','员工'],
      pickerD: ['未婚', '已婚', '离异'],
      showPickerA: false,
      showPickerB: false,
      showPickerC: false,
      showPickerD: false,
      orderInfo: {},
      addrStr: '',
      marriedStr: '',
      jobStr: ''
    }
  },
  computed: {
  },
  created () {
    // this.checkInfo = JSON.parse(window.localStorage.getItem('userCheckInfo') || '{}')
    let user = JSON.parse(window.localStorage.getItem('orderInfo') || '{}')
    this.orderInfo = user
    console.log(this.orderInfo, 'order')
    let job = parseInt(user.job || 0)
    let married = parseInt(user.married || 0)
    if (job > 0) {
      this.jobStr = this.pickerC[job - 1]
    }
    if (married > 0) {
      this.marriedStr = this.pickerD[married - 1]
    }
    this.initData()
  },
  methods: {
    initData () {
      if (this.orderInfo.provinceNo) {
        this.addrStr = `${this.orderInfo.provinceName}/${this.orderInfo.cityName}/${this.orderInfo.areaName}` 
      }
    },
    async getorderInfo () {
      let res = await this.$http.orderApi.getOrderInfo({courseNo: this.$route.query.id})
      this.orderInfo = res.msg || {}
    },
    onConfirm(values) {
      console.log(values, 'sss')
      this.addrStr = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.orderInfo.provinceNo = values[0].code
      this.orderInfo.cityNo = values[1].code
      this.orderInfo.areaNo = values[2].code
      this.orderInfo.provinceName = values[0].name
      this.orderInfo.cityName = values[1].name
      this.orderInfo.areaName = values[2].name
      this.showArea = false;
    },
    onConfirmA(value) {
      this.orderInfo.income = value;
      this.showPickerA = false;
    },
    onConfirmB(value) {
      this.orderInfo.education = value;
      this.showPickerB = false;
    },
    onConfirmC (value) {
      this.jobStr = value;
      for (let i = 0; i < this.pickerC.length; i++) {
        if (value == this.pickerC[i]) {
          this.orderInfo.job = (i+1)
        }
      }
      this.showPickerC = false;
    },
    onConfirmD (value) {
      this.marriedStr = value
      for (let i = 0; i < this.pickerD.length; i++) {
        if (value == this.pickerD[i]) {
          this.orderInfo.married = (i+1)
        }
      }
      this.showPickerD = false;
    },
    filterParams () {
      if (!this.orderInfo.workAddr) {
        this.$toast('请填写工作单位')
      } else if (!this.orderInfo.email) {
        this.$toast('请填写邮箱')
      } else if (!(this.orderInfo.provinceNo)) {
        this.$toast('请选择地址省市县')
      } else if (!(this.orderInfo.detailAddr)) {
        this.$toast('请填写详细地址')
      } else if (!this.orderInfo.income) {
        this.$toast('请填写收入')
      } else if (!this.orderInfo.education) {
        this.$toast('请填写学历')
      } else if (!this.orderInfo.job) {
        this.$toast('请选择职务')
      } else if (!this.orderInfo.married) {
        this.$toast('请选择婚姻状况')
      } else if (!this.orderInfo.contractName) {
        this.$toast('请填写紧急联系人姓名')
      } else if (!this.orderInfo.contractMobile) {
        this.$toast('请填写紧急联系人号码')
      } else {
        return true
      }
    },
    nextBtn () {
      if (this.filterParams()) {
        console.log('通过')
        window.localStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))
        this.$router.push('/user/student?courseId=' + this.$route.query.id + '&pageStatus=select')
      }
      // $router.push('/school/uploadInfo')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding-bottom: 30px;
  .top-box {
    height: 156px;
    background: url('~@/assets/img/page-bg.png') center center no-repeat;
    background-size: 100% 192px;
    padding-top: 88px;
    padding-left: 15px;
    .text-a {
      font-size: 17px;
      font-weight: bold;
      line-height: 40px;
    }
    .text-b {
      color: #7c7c7c;
      line-height: 16px;
      font-size: 13px;
    }
  }
  .submit-btn {
    width: 345px;
    margin: 30px auto 0;
    display: block;
  }
  
}
</style>
